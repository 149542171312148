<template>
  <div class="table-card__item-content"
       :class="{show: show}"
  >
    <div class="table-card__item-head">
      <div class="table-card__item-head-row align-items-center">
        <div class="table-card__item-info">
          #{{ item.id }}
        </div>
      </div>
    </div>
    <transition name="slide">
      <div class="table-card__item-body"
           v-if="show"
      >
        <div class="table-card__item-row custom-row">
          <div class="table-card__item-col custom-col">
            <div class="table-card__item-label">
              {{$t('shippingReport_created.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              {{ item.created_at | moment("DD MMM, YYYY") }}
            </div>
          </div>
          <div class="table-card__item-col custom-col">
            <div class="table-card__item-label">
              {{$t('shippingReport_trackingNumber.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              {{item.tracking_number}}
            </div>
          </div>
          <div class="table-card__item-col custom-col">
            <div class="table-card__item-label">
              {{$t('shippingReport_customer.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              {{item.customer_name}}
            </div>
          </div>
          <div class="table-card__item-col custom-col">
            <div class="table-card__item-label">
              {{$t('common_user.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              {{item.user_name}}
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('shippingReport_payPal.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              {{item.transaction_number}}
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('shippingReport_status.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <template v-if="item.status === 'in_progress'">
                {{$t('fbm_inProgress.localization_value.value')}}
              </template>
              <template v-if="item.status === 'completed'">
                {{$t('fbm_completed.localization_value.value')}}
              </template>
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('shippingReport_shippingCost.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              {{item.shipping_cost}}
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('shippingReport_weight.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              {{item.weight}}
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('shippingReport_length.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              {{item.length}}
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('shippingReport_width.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              {{item.width}}
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('shippingReport_height.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              {{item.height}}
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('shippingReport_city.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              {{item.sender_city}}
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('shippingReport_shipped.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              {{item.delivery_service}}
            </div>
          </div>

        </div>

<!--        <div class="table-card__item-btn pl-2">-->
<!--          <div class="table-card__item-btn-i">-->
<!--            <LinkButton-->
<!--                :value="$t('common_edit.localization_value.value')"-->
<!--                :target="'_blank'"-->
<!--                :type="'edit'"-->
<!--                :link="$store.getters.GET_PATHS.ordersFBM + '/' + getOrderFBMTypeById(item.type).name + '/' + item.id"-->
<!--            />-->
<!--          </div>-->
<!--        </div>-->

      </div>
    </transition>
    <div class="table-card__item-footer">
      <div class="table-card__item-show"
           @click="show = !show"
      >
        {{$t('common_showMore.localization_value.value')}}
      </div>
    </div>
  </div>
</template>

<script>
  // import DefaultCheckbox from "../../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";

  // import TableUserColumn from "../../../../../../../coreComponents/TableComponents/TableUserColumn/TableUserColumn";
  // import LinkButton from "../../../../../../../UI/buttons/LinkButton/LinkButton";
  import {FBMMixinsHelper} from "../../../../../../../../mixins/FBMMixins/FBMMixins";

  export default {
    name: "ShippingReportTableMobile",

    components: {
      // LinkButton,
      // TableUserColumn
    },

    mixins: [
      FBMMixinsHelper
    ],

    props: {
      item: Object,
      selectedNow: Boolean,
      countFilterParams: Number,
      navTabs: Object,
    },

    data() {
      return {
        show: false,
      }
    },

    methods: {

    },

  }
</script>

<style lang="scss" scoped>
</style>
