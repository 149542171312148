<template>
  <div>
    <ShippingReportHead
        :showFilter="showFilter"
        :navTabs="navTabs"
        :countFilterParams="countFilterParams"
        @toggleFilter="toggleFilter"
        @filter="$emit('filter')"
        @changeTab="(data) => $emit('changeTab', data)"
        @reload="$emit('reload')"
        @selectAll="selectAll"
    />

    <div class="table-filter-wrap">

      <ShippingReportFilter
          :showFilter="showFilter"
          :filterGetParams="filterGetParams"
          @close="closeFilter"
          @changeFilter="data => $emit('changeFilter', data)"
          @resetFilter="$emit('resetFilter')"
      />

      <ShippingReportTable
          :countFilterParams="countFilterParams"
          :selectedNow="selectedNow"
          :navTabs="navTabs"
          :consoUsers="consoUsers"
          @showMore="$emit('showMore')"
          @resetFilter="$emit('resetFilter')"
          @downloadFiles="type => $emit('downloadFiles', type)"
      />
    </div>
  </div>
</template>

<script>


import ShippingReportHead from "./ShippingReportHead/ShippingReportHead";
import ShippingReportFilter from "./ShippingReportFilter/ShippingReportFilter";
import ShippingReportTable from "./ShippingReportTable/ShippingReportTable";

export default {
  name: "ShippingReportTableUser",

  components: {
    ShippingReportHead,
    ShippingReportFilter,
    ShippingReportTable,
  },

  props: {
    typeShops: String,
    countFilterParams: Number,
    filterGetParams: Object,
    navTabs: Object,
    consoUsers: Array,
  },

  data(){
    return{
      showFilter: false,
      selectedNow: false,
    }
  },

  watch: {
    filterGetParams(newVal) {
      this.filterGetParams = newVal
    },
    countFilterParams(newVal) {
      this.countFilterParams = newVal
    },
  },

  methods: {
    closeFilter() {
      this.showFilter = false
    },

    toggleFilter() {
      this.showFilter = !this.showFilter
    },

    selectAll(val) {
      this.selectedNow = val
    },
  }
}
</script>

<style scoped>

</style>
