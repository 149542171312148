<template>
  <div class="fragment">
    <div class="table-filter-wrap">
      <div class="site-table-wrap small-table scrolling-macbook-table"
           v-if="$store.getters.getShippingReport.length && !$store.getters.getShippingReportLoading"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
            'shippingReport_Id',
            'shippingReport_created',
            'shippingReport_trackingNumber',
            'shippingReport_customer',
            'common_user',
            'shippingReport_payPal',
            'shippingReport_status',
            'shippingReport_shippingCost',
            'shippingReport_weight',
            'shippingReport_length',
            'shippingReport_width',
            'shippingReport_height',
            'shippingReport_city',
            'shippingReport_shipped',
            ])"></div>
        <table class="site-table mt-0" v-if="!isMobileFunc()">
          <thead>
          <tr>
            <th class="text-lg-center">{{$t('shippingReport_Id.localization_value.value')}}</th>
            <th>{{$t('shippingReport_created.localization_value.value')}}</th>
            <th>{{$t('shippingReport_trackingNumber.localization_value.value')}}</th>
            <th>{{$t('shippingReport_customer.localization_value.value')}}</th>
            <th>{{$t('common_user.localization_value.value')}}</th>
            <th>{{$t('shippingReport_payPal.localization_value.value')}}</th>
            <th>{{$t('shippingReport_status.localization_value.value')}}</th>
            <th>{{$t('shippingReport_shippingCost.localization_value.value')}}</th>
            <th>{{$t('shippingReport_weight.localization_value.value')}}</th>
            <th>{{$t('shippingReport_length.localization_value.value')}}</th>
            <th>{{$t('shippingReport_width.localization_value.value')}}</th>
            <th>{{$t('shippingReport_height.localization_value.value')}}</th>
<!--            <th>{{$t('shippingReport_comment.localization_value.value')}}</th>-->
            <th>{{$t('shippingReport_city.localization_value.value')}}</th>
            <th>{{$t('shippingReport_shipped.localization_value.value')}}</th>
<!--            <th>{{$t('common_manage.localization_value.value')}}</th>-->
          </tr>
          </thead>
          <tbody>

          <tr v-for="(item, index) in $store.getters.getShippingReport" :key="index">
            <td>
              {{item.id}}
            </td>
            <td>
              {{ item.created_at | moment("DD MMM, YYYY") }}
            </td>
            <td>
              {{item.tracking_number}}
            </td>
            <td>{{item.customer_name}}</td>
            <td>
              {{item.user_name}}
<!--              <TableUserColumn-->
<!--                  :item="item"-->
<!--              />-->
            </td>
            <td>{{item.transaction_number}}</td>
            <td>
              <template v-if="item.status === 'in_progress'">
                {{$t('fbm_inProgress.localization_value.value')}}
              </template>
              <template v-if="item.status === 'completed'">
                {{$t('fbm_completed.localization_value.value')}}
              </template>
            </td>
            <td>
              {{item.shipping_cost}}
            </td>
            <td>
              {{item.weight}}
            </td>
            <td>
              {{item.length}}
            </td>
            <td>
              {{item.width}}
            </td>
            <td>
              {{item.height}}
            </td>
            <td>
              {{item.sender_city}}
            </td>
            <td>
              {{item.delivery_service}}
            </td>
<!--            <td>-->
<!--              <ManagerButton @reloadManagerBtn="$store.commit('setGlobalUpdateManagerButton', Math.random()) "-->
<!--                             :key="$store.getters.getGlobalUpdateManagerButton">-->
<!--                <template slot="item"-->
<!--                          v-if="!(isCurrentUserRoleUser && isStatusComplete(item.status)) && !(isCurrentUserRoleUser && item.prepay === 1)"-->
<!--                >-->
<!--                  <LinkButton-->
<!--                      :value="$t('common_edit.localization_value.value')"-->
<!--                      :target="'_blank'"-->
<!--                      :type="'edit'"-->
<!--                      :link="$store.getters.GET_PATHS.ordersFBM + '/' + getOrderFBMTypeById(item.type).name + '/' + item.id"-->
<!--                  />-->
<!--                </template>-->
<!--              </ManagerButton>-->
<!--            </td>-->
          </tr>
          </tbody>
        </table>

        <div class="table-card" v-else>
          <div class="table-card__list pt-1">

            <div class="table-card__item"
                 v-for="(item, index) in $store.getters.getShippingReport"
                 :key="index"
            >
              <ShippingReportTableMobile
                  :item="item"
                  :navTabs="navTabs"
                  :selectedNow="selectedNow"
                  @removeItem="$emit('removeItem', item.id)"
                  @thermalPrint="(data) => $emit('thermalPrint', data)"
              />
            </div>
          </div>
        </div>

        <div class="table-bottom-btn">
          <div class="table-bottom-btn__inner">
            <ShowMore
                v-if="$store.getters.getShippingReportCommonList.next_page_url !== null && $store.getters.getShippingReport.length > 0"
                @click.native="$emit('showMore')"
                v-bind:class="{'disabled-btn' : $store.getters.getNextShippingReportPage}"
                :count="$store.getters.getShippingReportCommonList.total - $store.getters.getShippingReportForPage < $store.getters.getShippingReportForPage ?
                  $store.getters.getShippingReportCommonList.total - $store.getters.getShippingReportForPage:
                  $store.getters.getShippingReportForPage"
            />
            <ExportBtn
                v-if="navTabs.express.active"
                @downloadFiles="type => $emit('downloadFiles', type)"
                class="table-bottom-btn__right"/>
          </div>
        </div>
      </div>


      <template v-if="$store.getters.getShippingReportLoading === false && $store.getters.getShippingReport.length === 0">
        <NoResult
            :countFilterParams="countFilterParams"
            :title="$t('common_noDataYet.localization_value.value')"
            :titleWithFilter="$t('common_noResult.localization_value.value')"
            :text="$t('common_noDataYetTxt.localization_value.value')"
            :textWithFilter="$t('common_noResultSeems.localization_value.value')"
            @resetFilter="$emit('resetFilter')"
        />
      </template>
      <!--<ProductsToWarehouse @close="closePopup" v-if="isModalProductsToWarehouse"/>-->


    </div>
  </div>
</template>

<script>
  // import ShowMore from "../../../../../../coreComponents/Buttons/ShowMore/ShowMore";
  import {mixinDetictingMobile} from "../../../../../../../mixins/mobileFunctions";
  import NoResult from "../../../../../../coreComponents/NoResult/NoResult";
  import ShippingReportTableMobile from "./ShippingReportTableMobile/ShippingReportTableMobile";
  import ShowMore from "../../../../../../coreComponents/Buttons/ShowMore/ShowMore";
  import ExportBtn from "../../../../../../coreComponents/Buttons/Export/Export";
  // import TableUserColumn from "../../../../../../coreComponents/TableComponents/TableUserColumn/TableUserColumn";
  // import ManagerButton from "../../../../../../UI/buttons/ManagerButton/ManagerButton";
  // import LinkButton from "../../../../../../UI/buttons/LinkButton/LinkButton";
  import {FBMMixinsHelper} from "../../../../../../../mixins/FBMMixins/FBMMixins";

  export default {
    name: "ShippingReportTable",
    components: {
      // LinkButton,
      // ManagerButton,
      // TableUserColumn,
      ShowMore,
      ShippingReportTableMobile,
      NoResult,
      ExportBtn,
    },

    props: {
      selectedNow: Boolean,
      countFilterParams: Number,
      navTabs: Object,
    },

    mixins: [mixinDetictingMobile, FBMMixinsHelper],

    data(){
      return{
        show1: false,
        show2: false,
        show3: false,
        show4: false,
      }
    }
  }
</script>

<style lang="scss" scoped>

  td:first-child{
    text-align: center;
  }

  .site-table thead th,
  .site-table tbody td{
    padding-right: 40px;
  }

  .rank{
    display: flex;
    width: 30px;
    height: 30px;
    margin-left: auto;
    margin-right: auto;
    background-position: center center;
    background-repeat: no-repeat;

    &.rank-1{
      background-image: url("../../../../../../../assets/img/common/rank-1.svg");
    }

    &.rank-2{
      background-image: url("../../../../../../../assets/img/common/rank-2.svg");
    }

    &.rank-3{
      background-image: url("../../../../../../../assets/img/common/rank-3.svg");
    }
  }

</style>
