<template>
  <ShippingReportTableAdmin
      :navTabs="navTabs"
      :countFilterParams="countFilterParams"
      :filterGetParams="filterGetParams"
      @changeTab="changeTab"
      @resetFilter="resetFilter"
      @changeFilter="changeFilter"
      @showMore="showMore"
      @reload="reload"
      @downloadFiles="downloadFiles"
  />
</template>

<script>
import ShippingReportTableAdmin from "./ShippingReportTableAdmin/ShippingReportTableAdmin";
import {routeFilter} from "../../../../../mixins/routeFilterMixins/routeFilter";
export default {
  name: "ShippingReportTable",

  components: {
    ShippingReportTableAdmin,
  },

  mixins: [routeFilter],

  computed: {
    router: function() {
      return this.$route.query
    }
  },

  watch: {
    router: function(newRoute) {
      if(Object.keys(newRoute).length === 0){
        this.changeTab('storage')
      }
    },

    loadUserAuthorizedData: function () {
      // set tab active and visible by permissions
      if(this.downloadPermissions){
        this.checkTabPermission()
      }
    },

    downloadPermissions() {
      this.checkTabPermission()
    }
  },

  data() {
    return {
      forPage: this.$store.getters.getShippingReportForPage,
      page: 1,
      onePage: 1,
      filterType: '',

      filterOrderId: '',

      filterCreatedDate: '',

      filterContactName: '',
      filterTrackingNumber: '',
      filterRecipientEmail: '',
      filterComment: '',

      filterUserId: '',
      filterUserName: '',

      countFilterParams: 0,
      filterGetParams: {},

      navTabs: {
        express: {
          active: true,
          label: this.$t('shippingReport_express.localization_value.value'),
          name: 'express',
          type: 'express',
          id: false,
          permissionVisible: true,
        },
        inbound: {
          active: false,
          label: this.$t('shippingReport_inbound.localization_value.value'),
          name: 'inbound',
          type: 'inbound',
          id: false,
          permissionVisible: true,
        },
      },
    }
  },

  mounted() {
    if(this.$route.query.type !== undefined){
      this.navTabs.express.active = false
      this.navTabs[this.$route.query.type].active = true
    }

    if(this.loadUserAuthorizedData){
      // set tab active and visible by permissions
      if(this.downloadPermissions){
        this.checkTabPermission()
      }
    }
  },

  methods: {
    checkTabPermission() {

      // this.activeTabByPermissions('navTabs', {
      //   [DHL_DELIVERY_SERVICE.name]: this.PERMISSIONS.ORDER_EXPRESS_DHL,
      //   [FEDEX_DELIVERY_SERVICE.name]: this.PERMISSIONS.ORDER_EXPRESS_FEDEX,
      //   [TNT_DELIVERY_SERVICE.name]: this.PERMISSIONS.ORDER_EXPRESS_TNT,
      //   [DPD_DELIVERY_SERVICE.name]: this.PERMISSIONS.ORDER_EXPRESS_FEDEX,
      // })
      //
      // this.hideTabAllIfVisibleOnlyOne('navTabs', 'all', ['permissionVisible'])
      //
      // if(!this._.find(this.navTabs, {active: true}).permissionVisible){
      //   this.setActivePermissionVisibleTab('navTabs')
      //   return
      // }

      this.filter()
    },

    getFilterParam() {
      this.filterGetParams = {
        page: this.$route.query.page,
        count: this.$route.query.count,
        type: this.$route.query.type,

        orderId: this.$route.query.orderId,
        createdDate: this.$route.query.createdDate,

        contactName: this.$route.query.contactName,

        trackingNumber: this.$route.query.trackingNumber,
        recipientEmail: this.$route.query.recipientEmail,
        comment: this.$route.query.comment,

        userId: this.$route.query.userId,
        userName: this.$route.query.userName,
      }
    },

    filter(next = false) {
      this.getFilterParam()

      let url

      // generate main filter if has get params in URL
      let generateMainFilter = this.generateMainFilterFromUrl(next, this)

      // generate other filter if has get params in URL
      this.generateParamsFilterFromUrl(this)

      // Generate filter URL
      url = this.generateFilterUrl(
          generateMainFilter.page,
          generateMainFilter.forPage
      )

      let type = this._.find(this.navTabs, {active: true})?.type

      this.$store.commit('setShippingReportFilter', window.location.search)

      if (next) this.$store.commit('setNextShippingReportPage', true)
      this.$store.dispatch('fetchShippingReport', {filter: url, type}).then(() => {
        this.$store.commit('setNextShippingReportPage', false)
      })


      this.checkCountFilter(['userName', 'type'])
    },

    generateFilterUrl(page, forPage) {
      let myQuery = this.createQueryBuilderObject({withUserNewMark: true})

      // this.generateFilterQueryParams(
      //     myQuery,
      //     {
      //       filterOrderId: 'OrderFbmId',
      //       filterContactName: 'OrderFbmRecipientContactName',
      //
      //       filterTrackingNumber: 'OrderFbmTrackingNumber',
      //       filterRecipientEmail: 'OrderFbmRecipientEmail',
      //       filterComment: 'comment',
      //
      //       filterUserId: 'userId',
      //     },
      // )



      this.filterCreatedDate = this.$route.query.createdDate !== undefined ? this.$route.query.createdDate : ''
      if (this.filterCreatedDate.length > 0) {
        let date = this.generateDateForFilter(this.filterCreatedDate, 'YYYY-MM-DD')
        myQuery.whereIn(
            'between_created_at',
            date
        )
      }

      return this.generateGetParamsFromQuery(myQuery, forPage, page)


    },

    downloadFiles(type) {
      let forPage, page, url, exportIds

      exportIds = this.getCheckedRows('row-name')

      // generate main filter if has get params in URL
      let generateMainFilter = this.generateMainFilterFromUrl(false, this)

      page = generateMainFilter.page
      forPage = generateMainFilter.forPage

      if (type === 'xls') {
        type = 'xlsx'
      }

      url = this.generateFilterUrl(page, forPage, exportIds)

      this.$store.dispatch('getExportShippingReportExpress', {filter: url, exportType: type}).then((response) => {
        if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
          const blob = new Blob([response.data], {type: `application/${type}`})
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = `Shipping Report.${type}`
          link.click()
          URL.revokeObjectURL(link.href)
        } else {
          setTimeout(() => {
            this.openNotify('error', 'common_notificationUndefinedError')
          }, 200)
        }
      }).catch(error => this.createErrorLog(error))
    },

  }
}
</script>

<style scoped>

</style>